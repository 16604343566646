<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { generateFieldsSchema } from '@brain/core'
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import * as yup from 'yup'
import _ from 'lodash'

import service from '../api/takeUpUsBucketService'
import takeUpUsBucketImportanceService from '../api/takeUpUsBucketImportanceService'
import takeUpUsBucketGradeService from '../api/takeUpUsBucketGradeService'
import takeUpUsBucketIntrinsicService from '../api/takeUpUsBucketIntrinsicService'
import takeUpUsBucketContaminationService from '../api/takeUpUsBucketContaminationService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      reloadData: false,
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getTakeUpUsBucketImportanceMeta() {
      const takeUpUsBucketImportanceMeta =
        await takeUpUsBucketImportanceService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpUsBucketImportanceMeta,
        'takeUpUsBucketImportance',
        'usBucketId',
        'usBucket',
        takeUpUsBucketImportanceService
      )
      return takeUpUsBucketImportanceMeta
    },
    async getTakeUpUsBucketGradeMeta() {
      const takeUpUsBucketGradeMeta =
        await takeUpUsBucketGradeService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpUsBucketGradeMeta,
        'takeUpUsBucketGrade',
        'usBucketId',
        'usBucket',
        takeUpUsBucketGradeService
      )
      return takeUpUsBucketGradeMeta
    },
    async getTakeUpUsBucketIntrinsicMeta() {
      const takeUpUsBucketIntrinsicMeta =
        await takeUpUsBucketIntrinsicService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpUsBucketIntrinsicMeta,
        'takeUpUsBucketIntrinsic',
        'usBucketId',
        'usBucket',
        takeUpUsBucketIntrinsicService
      )
      return takeUpUsBucketIntrinsicMeta
    },
    async getTakeUpUsBucketContaminationMeta() {
      const takeUpUsBucketContaminationMeta =
        await takeUpUsBucketContaminationService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpUsBucketContaminationMeta,
        'takeUpUsBucketContamination',
        'usBucketId',
        'usBucket',
        takeUpUsBucketContaminationService
      )
      return takeUpUsBucketContaminationMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getTakeUpUsBucketImportanceMeta(),
        this.getTakeUpUsBucketGradeMeta(),
        this.getTakeUpUsBucketIntrinsicMeta(),
        this.getTakeUpUsBucketContaminationMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      meta.actions = [
        {
          title: ({ node }) => {
            return `${node.data.default}` == 'true'
              ? this.$t('controlTower.pages.takeUpUsBucket.makeNotDefault')
              : this.$t('controlTower.pages.takeUpUsBucket.makeDefault')
          },
          className: ({ node }) => {
            return `${node.data.default}` == 'true'
              ? 'fad fa-toggle-on'
              : 'fad fa-toggle-off'
          },
          enable: true,
          callback: ({ node }) => {
            this.onChangeTableDefaultHandler(node.data)
          }
        }
      ]

      const fieldsById = _.keyBy(meta.fields, (field) => field.id)
      fieldsById.default.validations.required = false
      fieldsById.default.onValueChange = (changedValue, fields) => {
        fields.default.disabled = true
      }

      return meta
    },
    async onChangeTableDefaultHandler(usBucket) {
      await this.$brain.confirm(
        {
          title: this.$t('core.crud.attention'),
          subtitle: usBucket.default
            ? this.$t('controlTower.pages.takeUpUsBucket.removeDefaultMessage')
            : this.$t('controlTower.pages.takeUpUsBucket.changeDefaultMessage')
        },
        async () => await this.changeTableDefaultById(usBucket.id)
      )
    },
    async changeTableDefaultById(id) {
      const updated = await service.changeTableDefaultById(id)
      if (updated) {
        this.reloadData = true
      }
    },
    generateValidationSchema() {
      this.details.takeUpUsBucketIntrinsic.validationSchema = (
        meta,
        translate
      ) => {
        const validationSchema = generateFieldsSchema(meta, translate)

        const minValidationSchema = validationSchema.min
        validationSchema.min = minValidationSchema.min(0)

        const maxValidationSchema = validationSchema.max
        validationSchema.max = maxValidationSchema.when(['min'], {
          is: (min) => min !== null && min !== undefined,
          then: maxValidationSchema.moreThan(yup.ref('min')),
          otherwise: maxValidationSchema.min(0)
        })

        const avgValidationSchema = validationSchema.avg
        validationSchema.avg = avgValidationSchema
          .when(['min'], {
            is: (min) => min !== null && min !== undefined,
            then: avgValidationSchema.min(yup.ref('min')),
            otherwise: avgValidationSchema.min(0)
          })
          .when(['max'], {
            is: (max) => max !== null && max !== undefined,
            then: avgValidationSchema.max(yup.ref('max')),
            otherwise: avgValidationSchema
          })

        return yup.object().shape(validationSchema)
      }
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
      this.generateValidationSchema()
    })
  }
}
</script>
