import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpUsBucketIntrinsic',
  api,
  { importLogUrl: '/api/controltower/importLog', masterName: 'usBucketId' }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.usBucket.options = ctx.usBucketOptions
  fieldsById.greatness.options = ctx.greatnessOptions
}

export default service
