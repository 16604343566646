import { api, createCrudService, handleError } from '@cargill/shared'

const service = createCrudService('/api/controltower/takeUpUsBucket', api, {
  importLogUrl: '/api/controltower/importLog'
})

service.changeTableDefaultById = async (id) => {
  try {
    await api.put(
      `/api/controltower/takeUpUsBucket/changeTableDefaultById/${id}`
    )
    return true
  } catch (error) {
    handleError(error)
    return false
  }
}

export default service
